import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Project Management Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/project-management-web-app/"
    metaDescription="Design your own project management web app with our project management web app template. Drag, drop, and customize today."
    description="
    h2:Project management = streamlined, with our project management website design template
    <br/>
    Easily manage your project management web app build with our brand-new <a:https://uizard.io/templates/web-app-templates/>web app template</a>. Save time building from the ground up and take advantage of our template's chic, simplistic, and streamlined design. With everything your design could possibly need, our web app design template is ready to go, straight out of the box.
    <br/>
    h3:Manage your design flow like never before
    <br/>
    Use our project management app design template as it is or update it to suit your own brand vision and aesthetic. Uizard comes with a huge range of drag-and-drop components, as well as loads of built-in edit functionality. Make your project management web app design today and make it truly your own.
    <br/>
    h3:Rapidly complete your design project with Uizard
    <br/>
    Simply sign up to Uizard, select the template, and get started. You can have your design finished and ready for demonstration in minutes. Uizard also makes it easier to collaborate than ever before with its smart sharing and collaboration features! Whether you want a <a:https://uizard.io/wireframing/>UX wireframing tool</a>, something for mockups, or a way to design high-fidelity prototypes, Uizard has got you covered.
    "
    pages={[
      "A stunning project management app interface page with best practice side navigation",
      "A calendar screen demonstrating streamlined organization and day/week/month visualizations",
      "A chic and stylish project overview page demonstrating all the necessary components your design should include for easy navigation and use",
      "Reporting and new task addition screens to cover all the core areas of your project management web app design",
    ]}
    projectCode="d9RWbM0PznIGa4K0ePZW"
    img1={data.image1.childImageSharp}
    img1alt="project management web app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="project management web app design template charts screen"
    img3={data.image3.childImageSharp}
    img3alt="project management web app design template project detail screen"
    img4={data.image4.childImageSharp}
    img4alt="project management web app design template report screen"
    img5={data.image5.childImageSharp}
    img5alt="project management web app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/project-management-web-app/project-management-web-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/project-management-web-app/project-management-web-app-chart.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/project-management-web-app/project-management-web-app-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/project-management-web-app/project-management-web-app-report.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/project-management-web-app/project-management-web-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
